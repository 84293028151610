import React, { useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { FloatingLabel, Button, Spinner, Alert } from "react-bootstrap";
import { graphql } from "gatsby";
import Turnstile from "react-turnstile";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";

const Contact = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState();

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setLoading(true);

    fetch("/api/forms/submit", {
      method: "POST",
      body: new FormData(e.target),
    })
      .then((res) => res.json())
      .then((res) => {
        const { success } = res;

        if (success) {
          e.target.reset();
          setSubmitStatus({
            message: "Thank you for your message.",
            type: "success",
          });
        } else {
          setSubmitStatus({
            message: "Something went wrong. Please try again later.",
            type: "danger",
          });
        }
      })
      .catch(() => {
        setSubmitStatus({
          message: "Something went wrong.",
          type: "danger",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <Seo page={page} />
      <Layout>
        <div className="contact-container">
          <div className="row inner">
            <div className="col-11 col-md-8 col-lg-5 col-xl-4 col-xxl-3 offset-xxl-2 order-2 order-lg-1">
              <Form
                className="contact-form"
                onSubmit={onSubmit}
                name="contact-form"
              >
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label visuallyHidden="true">Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPhone">
                  <Form.Label visuallyHidden="true">Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    pattern="[0-9]*"
                    maxLength={16}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label visuallyHidden="true">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    required
                  />
                </Form.Group>
                <FloatingLabel controlId="formTextarea" className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Message"
                    name="message"
                    style={{ height: "200px", width: "100%" }}
                    required
                  />
                </FloatingLabel>
                <Turnstile
                  sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
                  theme="dark"
                  onError={() => setDisable(true)}
                  onExpire={() => setDisable(true)}
                  onVerify={() => setDisable(false)}
                  className="mb-3"
                />
                <Button
                  type="submit"
                  disabled={disable || loading}
                  className="services-btn m-0"
                  variant="secondary"
                >
                  Submit
                  {loading && (
                    <Spinner animation="border" size="sm" className="ms-2" />
                  )}
                </Button>
              </Form>
              {submitStatus && (
                <Alert
                  variant={submitStatus.type}
                  className="my-3 text-center"
                  onClose={() => setSubmitStatus()}
                  dismissible
                >
                  {submitStatus.message}
                </Alert>
              )}
            </div>
            <div className="col-11 col-md-8 col-lg-5 order-1 order-lg-2">
              <div className="mt-5 mt-md-0 mb-5 mb-lg-0 ms-lg-4">
                <div className="d-flex text-white">
                  <span className="me-2">Address&#58;</span>
                  {page.data.address}
                </div>
                <div className="d-flex text-white">
                  <span className="me-2">Mobile &#38; WhatsApp&#58;</span>
                  <a href={`tel:${page.data.mobile}`}>{page.data.mobile}</a>
                </div>
                <div className="d-flex text-white">
                  <span className="me-2">Email&#58;</span>
                  <a href={`mailto:${page.data.email}`}>{page.data.email}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query contactQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicContact {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        address
        email
        mobile
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
      }
    }
  }
`;

export default Contact;
